import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "home",
  useLayout: true,
  children: [
    { id: SectionsKey.Hero, order: 1 },
    { id: SectionsKey.Brand, order: 2 },
    { id: SectionsKey.RecentlyView, order: 3 },
    { id: SectionsKey.TopCategories, order: 4 },
    { id: SectionsKey.BestSeller, order: 5},
    { id: SectionsKey.FeatureProduct, order: 6 },
    { id: SectionsKey.NewCollections, order: 7 },
    { id: SectionsKey.Trending, order: 8 },
    { id: SectionsKey.Recommended, order: 9 },
    { id:SectionsKey.Testimonial, order: 10, dataKey:SectionsKey.Testimonial },
    { id: SectionsKey.Newsletter, order: 11 },
     
  ],
};

export default PageStruct;
