import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "order-detail",
  useLayout: true,
  children: [
    { id: "order-detail", order: 1, dataKey: SectionsKey.OrderDetail },
  ],
};

export default PageStruct;
