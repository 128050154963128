import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'blog-list',
    useLayout: true,
    children: [
        { id: 'blog-detail', order: 1, dataKey: SectionsKey.BlogDetail },
        { id: 'related-blogs', order: 2, dataKey: SectionsKey.RelatedBlogs },
        { id: 'newsletter', order: 3 }
    ]
};

export default PageStruct;
