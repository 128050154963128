import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'categories',
    useLayout: true,
    children: [
        { id: 'categories', order: 1, dataKey: SectionsKey.Category },
        { id: 'newsletter', order: 2 }
    ]
};

export default PageStruct;
