import { GetServerSideProps } from "next";
import { FC } from "react";

import Layout from "~/components/Layout";

import LoadingPage from "~/components/LoadingPage";
import { Product, ProductVariant } from "~/services/product";
interface ProductDetailProps {
  product: Product;
  slug: string;
  faqData: any;
  dataMeta: any;
  defaultVariant: ProductVariant;
}


export enum ProductDetailDataKey {
  CustomersWhoViewedThisItemAlsoViewed = "customers_who_viewed_this_item_also_viewed",
  CustomersWhoBoughtThisItemAlsoBought = "customers_who_bought_this_item_also_bought",
  ExclusiveItemsFromOurBrands = "exclusive_items_from_our_brands",
  InspiredByYourBrowsingHistory = "inspired_by_your_browsing_history",
  ProductsRelatedToThisItem = "products_related_to_this_item",
  TrendingProducts = "trending-products",
  PopularProducts = "popular-products",
}

const ProductDetail: FC<ProductDetailProps> = (props) => {
  
  return (
    <>
      <Layout isUseMinHeight>
           <LoadingPage text="Redirecting..."/>
        </Layout>
    </>
  );
};

export default ProductDetail;

const ssrCache = new Map();
export const getServerSideProps: GetServerSideProps = async ({ params, res, query, req }) => {
  return {
    redirect: {
      destination: `/products/${params?.slug as string}`,
      statusCode: 301
  }
  }
};