const PageStruct = {
  id: "forgot-password",
  useLayout: true,
  children: [
    { id: "forgot-password", order: 1 },
    { id: "newsletter", order: 2 },
  ],
};

export default PageStruct;
