const PageStruct = {
  id: "sign-in",
  useLayout: true,
  children: [
    { id: "sign-in", order: 1 },
    { id: "newsletter", order: 2 },
  ],
};

export default PageStruct;
