import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "home",
  useLayout: true,
  children: [
    { id: SectionsKey.Hero, order: 1 },
    { id: SectionsKey.Brand, order: 2 },
    // Chưa có apis
    { id: SectionsKey.ProductMatching, order: 3 },
    { id: SectionsKey.RecentlyView, order: 4 },

    // Chưa có apis
    { id: SectionsKey.TopCategories, order: 5 },

    { id: SectionsKey.BestSeller, order: 6 },
    { id: SectionsKey.MostPopular, order: 7 },

    // Chưa có apis
    { id: SectionsKey.FeatureProduct, order: 8 },
    // Chưa có apis
    { id: SectionsKey.NewCollections, order: 9 },
    { id: SectionsKey.Trending, order: 10 },
    {
      id: SectionsKey.Testimonial,
      order: 11,
      dataKey: SectionsKey.Testimonial,
    },
    // Chưa có apis
    { id: SectionsKey.Recommended, order: 12 },
    { id: SectionsKey.Newsletter, order: 13 },
  ],
};

export default PageStruct;
