const PageStruct = {
  id: "thank-you",
  useLayout: true,
  children: [
    { id: "thank-you", order: 1 },
    { id: "offer-products", order: 2 },
    { id: "newsletter", order: 3 },
  ],
};

export default PageStruct;
