import { SectionsKey } from '~/constants/enum';

const PageStruct = {
    id: 'blog-list',
    useLayout: true,
    children: [
        // { id: "blog-slider", order: 1 },
        { id: 'blog-list', order: 2, dataKey: SectionsKey.BlogList },
        { id: 'newsletter', order: 3 }
    ]
};

export default PageStruct;
