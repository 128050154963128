const PageStruct = {
  id: "wishlist",
  useLayout: true,
  children: [
    { id: "wishlist", order: 1, dataKey: "wishlist" },
    // { id: "newsletter", order: 2 },
  ],
};

export default PageStruct;
