import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "thank-you",
  useLayout: true,
  children: [
    { id: "thank-you", order: 1 },
    { id: SectionsKey.RecentlyView, order: 2 },
    // { id: "offer-code", order: 3 },
    // { id: "newsletter", order: 4 },
  ],
};

export default PageStruct;
